import BankIcon from "component/Icon/icon.component";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const regex = {
  completeCharSet: {
    typed: /^[\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß/?:().,'+\-&]+$/,
    paste: /[^\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß/?:().,'+\-&]/g,
  },
  restrictedCharSet: {
    typed: /^[\s0-9a-zA-Z]+$/,
    paste: /[^\s0-9a-zA-Z]/g,
  },
  integerCharSet: {
    typed: /^(?:[0-9]\d*)$/,
    paste: /[^\d]/g,
  },
  emailCharSet: {
    typed: /^[\s0-9a-zA-Z.-@]+$/,
    paste: /[\s0-9a-zA-Z.-@]/g,
  },
};
const SearchInputText: React.FC<iProps> = (props: iProps) => {
  const { t } = useTranslation();
  const {
    onChange,
    value,
    errore,
    img,
    imgOnClick,
    inError,
    maxChar,
    disable,
    outlineImg,
    outlineImgTooltip,
    outlineImgOnClick,
    restrictedCharSet,
    email,
    placeholder,
    integer,
    smallText,
    onFocusInput,
    focusSearch,
    searchInputRef,
    onkeydown,
  } = props;

  const [focus, setFocus] = useState<boolean>(focusSearch || false);

  const focusClass = "form-field__label_focus";
  let classNameInput = "form-field__input"; // form-field__input__disable
  if (img) {
    classNameInput += " form-field__input_btn";
  }
  if (disable) {
    classNameInput += " form-field__input__disable";
  }
  if (smallText) {
    classNameInput += " small-text-autocomplete";
  }
  useEffect(() => {
    if (value) {
      setFocus(true);
    }
  }, [value]);

  const onFocus = (focus: boolean) => {
    !value && setFocus(focus);
  };

  const getEtichettaIcon = (img: string) => {
    switch (img) {
      case "navigazione-search":
        return t("accessibilita.searchBar.avviaRicerca");
      case "azioni_elimina":
        return t("accessibilita.searchBar.chiudiRicerca");
      default:
        return undefined;
    }
  }

  return (
    <div className={`input-control input-control-text  `}>
      <div className="form-field">
        <div className="form-field__control">
          <input
            type="text"
            ref={searchInputRef}
            maxLength={maxChar}
            className={classNameInput}
            disabled={disable ? disable : false}
            placeholder={placeholder || ""}
            onKeyDown={(e: any) => onkeydown(e)}
            value={value ? value : disable ? "-" : ""}
            onChange={(e: any) => {
              const re = integer
                ? regex.integerCharSet.typed
                : restrictedCharSet
                  ? regex.restrictedCharSet.typed
                  : email
                    ? regex.emailCharSet.typed
                    : regex.completeCharSet.typed;
              if (e.target.value == "" || re.test(e.target.value)) {
                const inputValue =
                  e.target.value.startsWith("0") &&
                    e.target.value.length > 1 &&
                    integer
                    ? e.target.value.slice(1)
                    : e.target.value;
                onChange && onChange(inputValue, e);
              }
            }}
            title={disable && value ? value : ""}
            onBlur={() => {
              onFocusInput(false);
              onFocus && onFocus(false);
            }}
            onPaste={(e: any) => {
              e.preventDefault();
              let paste = e.clipboardData.getData("text");
              const re = integer
                ? regex.integerCharSet.paste
                : restrictedCharSet
                  ? regex.restrictedCharSet.paste
                  : email
                    ? regex.emailCharSet.paste
                    : regex.completeCharSet.paste;
              paste = paste.substring(0, maxChar).replace(re, "");
              onChange && onChange(paste, e);
            }}
            onFocus={(e) => {
              onFocusInput(true);
              onFocus && onFocus(true);
            }}
          />
          <div className="form-field__bar"></div>
          {img && <BankIcon title={img} onClick={imgOnClick || undefined} etichetta={getEtichettaIcon(img)} />}
        </div>
      </div>

      {inError && <p className="input-control-error">{errore}</p>}
    </div>
  );
};

interface iProps {
  onChange?: any;
  onkeydown?: any;
  onFocusInput?: any;
  spksCharset?: boolean;
  value?: string;
  errore?: string;
  img?: any;
  imgOnClick?: any;
  outlineImg?: any;
  outlineImgTooltip?: string;
  outlineImgOnClick?: any;
  maxChar?: number;
  inError?: boolean;
  disable?: boolean;
  restrictedCharSet?: boolean;
  email?: boolean;
  placeholder?: string;
  integer?: boolean;
  focusSearch?: boolean;
  smallText?: boolean;
  searchInputRef?: any;
}

export default SearchInputText;
